import React, { forwardRef, useEffect, useReducer, useRef } from 'react';
import ClassNames from 'classnames';
import { ClickableCard } from '../cards';

import { SDCOption, SDCOptionProps } from './sdc-option/sdc-option-wrapper';
import { SDCOptionList } from './sdc-option-list/sdc-option-list';
import { SDCTextOption } from './sdc-option/standard-option/sdc-text-option';
import useComponentId from '../../../hooks/use-component-id';
import { SelectDropdownState, SelectDropdownStateAction, SelectDropdownStateActionNames, selectDropdownStateReducer } from './select-dropdown-state-manager';
import { useNumberSequence } from '../../../hooks';
import { useRefAssigner } from '../../../hooks/use-ref-assigner';

import './select-dropdown.scss';

interface SelectDropdownProps<GenericObject> {
    id?: string,
    ariaLabel: string;
    className?: string;
    ListItemComponent: React.ComponentType<SDCOptionProps<GenericObject>>;
    hint?: string;
    isDisabled?: boolean;
    onSelect?: (option: SDCOption<GenericObject>) => void;
    options: SDCOption<GenericObject>[];
    testId?: string;
    labelName?: string;
}

export const SelectDropdown = forwardRef(function <GenericObject>({
    id,
    ariaLabel,
    className = '',
    ListItemComponent,
    hint = 'Please select an option',
    isDisabled = false,
    onSelect,
    options,
    testId = 'select-dropdown',
    labelName,
}: SelectDropdownProps<GenericObject>,
    ref: React.RefCallback<HTMLSelectElement> | React.MutableRefObject<HTMLSelectElement>,
): React.ReactElement {
    const listId = useComponentId('SDCOptionList');
    const selectRef = useRef<HTMLDivElement>();
    const selectInputRef = useRef<HTMLSelectElement>(null);

    const keySeq = useNumberSequence();

    // Define the initial state here so GenericObject works. (Typescript issue)
    // This is necessary because there is nothing to wrap this in inside the
    // state manager module.
    const INITIAL_SELECT_DROPDOWN_STATE: SelectDropdownState<GenericObject> = {
        activeElement: null as HTMLLIElement,
        selectedOption: null as SDCOption<GenericObject>,
        showList: false,
        showOnTop: false,
    };

    const [selectState, selectStateDispatcher] = useReducer<React.Reducer<SelectDropdownState<GenericObject>, SelectDropdownStateAction<GenericObject>>>(
        // eslint-disable-next-line prettier/prettier
        selectDropdownStateReducer<GenericObject>, // lint thinks this should be a function call, but it can't be.
        INITIAL_SELECT_DROPDOWN_STATE,
    );


    useEffect(() => {
        const localOptions = options || [];
        let selected = null;

        for (const option of localOptions) {
            if (selected == null) {
                selected = option;
            } else if (option.selected) {
                selected = option;
                break;
            }
        }

        selectStateDispatcher({
            type: SelectDropdownStateActionNames.SET_SELECTED_OPTION,
            payload: { option: selected },
        });

    }, [options]);

    useEffect(() => {
        if (selectState.showList) {
            selectStateDispatcher({ type: calculateState(selectRef.current) });
            return;
        } else {
            const optionsElement: HTMLDivElement = selectRef.current.querySelector('.select-dropdown__options');
            optionsElement.style.height = '';
            optionsElement.style.bottom = '';
        }

        selectStateDispatcher({ type: SelectDropdownStateActionNames.CLEAR_ACTIVE_ELEMENT });
    }, [selectState.showList]);

    useEffect(() => {
        selectInputRef.current.value = selectState.selectedOption?.value?.toString();
    }, [selectState.selectedOption]);

    const calculateState = (selectDropdownElement: HTMLDivElement) => {
        const windowHeight = window.innerHeight;
        const selectElement = selectDropdownElement.querySelector('.select-dropdown__current');
        const selectRect = selectElement.getBoundingClientRect();
        const listElement: HTMLDivElement = selectDropdownElement.querySelector('.sdc-option-list');
        const listRect = listElement.getBoundingClientRect();
        const optionsElement: HTMLDivElement = selectDropdownElement.querySelector('.select-dropdown__options');
        let dispatchAction = SelectDropdownStateActionNames.UNSET_SHOW_ON_TOP;

        if (
            listRect.bottom > windowHeight &&
            (listRect.height < selectRect.top || selectRect.top > windowHeight - selectRect.bottom)
        ) {
            dispatchAction = SelectDropdownStateActionNames.SET_SHOW_ON_TOP;
            optionsElement.style.bottom = `${selectRect.height}px`;
        } else {
            dispatchAction = SelectDropdownStateActionNames.UNSET_SHOW_ON_TOP;
            optionsElement.style.bottom = '';
        }

        return dispatchAction;
    };

    // This is mostly paranoid coding.  As things are, this should not be possible
    // But don't crash if it happens.
    const hintOption: SDCOption<null> = {
        value: null,
        label: hint,
    };

    const toggleShow = () => {
        selectStateDispatcher({ type: SelectDropdownStateActionNames.TOGGLE_SHOW_LIST });
    };

    const handleCloseList = () => {
        selectStateDispatcher({ type: SelectDropdownStateActionNames.UNSET_SHOW_LIST });
        const dropdownElement = selectRef.current?.querySelector('.select-dropdown__current') as HTMLElement;

        // Okay, yes, this paranoid coding.
        // The vast majority of the time, this handler will not be called after the component has been
        // dismounted.  But there is a race condition with a very small window which could allow that to
        // happen.  In such a case, the attempt to call `focus()` here would cause an error.  The error
        // is meaningless and noisy, so just avoid making it happen.
        if (dropdownElement) {
            dropdownElement.focus();
        }
    };

    const handleChangeActiveElement = (optionElement: HTMLLIElement) => {
        selectStateDispatcher({
            type: SelectDropdownStateActionNames.SET_ACTIVE_ELEMENT,
            payload: {
                element: optionElement,
            },
        });
    };

    const handleOnSelect = (option: SDCOption<GenericObject>) => {
        selectStateDispatcher({
            type: SelectDropdownStateActionNames.SET_SELECTED_OPTION,
            payload: { option },
        });
        if (selectInputRef.current) {
            selectInputRef.current.value = option.value.toString();
        }
        onSelect(option);
    };

    const handleSelectInputChange = () => {
        const value = selectInputRef.current.value;
        const optionsList = Array.from(selectRef.current.querySelectorAll<HTMLLIElement>('.sdc-option'));
        const optionComponent = optionsList.find((el: HTMLLIElement) => {
            return el.dataset['value'] === value;
        });

        optionComponent?.click();
    };

    const currentClasses = ClassNames('select-dropdown__current', {
        'select-dropdown__current--list-showing': selectState.showList,
        'select-dropdown__current--list-on-top': selectState.showOnTop,
    });

    const chevronClasses = ClassNames('fas', {
        'fa-chevron-up': selectState.showList,
        'fa-chevron-down': !selectState.showList,
    });

    const refAssigner = useRefAssigner<HTMLSelectElement>(ref, selectInputRef);

    return (
        <article
            data-testid={testId}
            ref={selectRef}
            className={`select-dropdown ${className}`}
            data-value={selectState.selectedOption ? selectState.selectedOption.value : ''}
            role="combobox"
            aria-label={ariaLabel}
            aria-haspopup="listbox"
            aria-controls={listId}
            aria-labelledby=""
            aria-expanded={selectState.showList}
            aria-activedescendant={selectState.activeElement?.id || ''}
        >
            {labelName && id && <label data-testid='select-dropdown-label' className='select-dropdown__label' htmlFor={id}>{labelName}</label> }
            <select
                ref={refAssigner}
                hidden={true}
                data-testid="select-dropdown-select-input"
                defaultValue={selectState.selectedOption ? selectState.selectedOption.value : ''}
                onChange={handleSelectInputChange}
                id={id}
                disabled={isDisabled}
            >
                {options.map((option: SDCOption<GenericObject>) => <option key={keySeq()} value={option.value}>{option.label}</option>)}
            </select>

            <ClickableCard
                testId={`${testId}-card`}
                cardName="select-dropdown"
                className={currentClasses}
                onClick={toggleShow}
                isDisabled={isDisabled}
            >
                {selectState.selectedOption ? (
                    <ListItemComponent option={selectState.selectedOption} isSelectLabel={true} />
                ) : (
                    <SDCTextOption option={hintOption} />
                )}
                <i className={chevronClasses}></i>
            </ClickableCard>

            <div className="select-dropdown__options z-index-select-dropdown-options">
                <SDCOptionList<GenericObject>
                    options={options}
                    selectElement={selectRef.current}
                    listId={listId}
                    ListItemComponent={ListItemComponent}
                    shown={selectState.showList}
                    shownOnTop={selectState.showOnTop}
                    onSelect={handleOnSelect}
                    onChangeActiveElement={handleChangeActiveElement}
                    onClose={handleCloseList}
                />
            </div>
        </article>
    );
});
